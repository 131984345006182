@charset "UTF-8";
/* CSS Document */

/* COLORS
 * dark green: #03533a
 * middle green: #296d57
 * light green: #81a99d
*/

/* GENERAL */
body {
    background-color: #03533a;
}
img {
    /*border: 4px solid #fff;*/
}
ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.row {
    margin-left: 0px;
    margin-right: 0px;
}
.active {
    background-color: #81a99d;
}
.intrinsic-container {
    position: relative;
    height: 0;
    overflow: hidden;
}
.intrinsic-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.intrinsic-container-16x9 {
        padding-bottom: 60%;
}
.featureImage {
    width: 350px;
    max-width: 100%;
    height: 250px;
    /*border: 4px solid #2B7461;*/
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.bannerImage {
    width: 1140px;
    max-width: 100%;
    height: 400px;
    border: 4px solid #FFF;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.teamImage {
    width: 225px;
    height: 225px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
@media (max-width: 1200px){ 
    .featureImage {
        width: 100%;
        height: 300px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}
@media (max-width: 991px){ 
    .featureImage {
        width: 100%;
        height: 300px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .bannerImage {
        width: 100%;
        height: 500px;
        border: 4px solid #FFF;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}


.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9,
 .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 0px;
    padding-right: 0px;
}

@media (min-width: 780px){
    .container {
        padding-left: 0px;
        padding-right: 0px;
    }
}


/* TYPOGRAPHY */
/*************/
h1, h2, h3, h4, h5, h6 {
    font-family: "alternate-gothic-no-3-d", sans-serif;
    text-transform: uppercase;
    color: #FFF;
}
h1 {
    font-size: 40px;
    font-weight: 400;
    letter-spacing: .6px;
    margin: 10px 0 6px 25px;
    text-align: left;
}
h2 {
    font-size: 27px;
    font-weight: 400;
    letter-spacing: .6px;
}
h3 {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: .6px;
}
h4 {
    font-size: 22px;
    font-weight: 400;
    letter-spacing: .6px;
}
h5 {
    font-size: 17px;
    font-weight: 400;
    letter-spacing: .6px;
}
h6 {
    font-size: 14px;
    font-weight: 400;
    padding: 10px 0px;
    margin-top: 20px;
    letter-spacing: .6px;
}
p {
    font-size: 18px;
    font-family: "proxima-nova", sans-serif;
    color: #FFF;
}
.grnblt {
    color: #81a99d;
    margin-right: 4px;
}


/* Buttons */
/***********/
.button {
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-size: 18px;
    width: 130px;
    background-color: #296d57;
    margin-top: 30px;
    padding: 10px;
    letter-spacing: .6px;
}
.button:hover {
    text-decoration: none;
    color: white;
    background-color: #81a99d;
}
/*.button > h3:hover{
    text-decoration: none;
}*/



/* LINKS */
/*********/
a:link {
    color: #FFF;
    text-decoration: none;
}
a:visited {
    color: #FFF;
    text-decoration: none;
}
a:hover {
    color: #FFF;
    text-decoration: none;
}
a:active {
    color: #FFF;
    text-decoration: none;
}




/* NAV */
/*******/
.navbar {
    font-family: "alternate-gothic-no-3-d";
    padding: 30px 0px;
    border: 0;
}
.navbar ul {
    padding-right: 0px;
    margin-right: 0px;
    margin-top: 30px;
    float: left;
}
.navbar-brand img {
    border: none;
    width: 211px;
}
.navbar-nav ul {
    text-align: center;
}
.navbar-nav li {
    text-transform: uppercase;
    text-align: center;
    font-size: 18px;
    width: 153px;
    /*width: 179px;*/
    background-color: #296d57;
    margin: 0px 2px;
    letter-spacing: .6px;
}
.navbar-nav li a:hover {
    background-color: #81a99d;
}
.navbar a {
     color: white;
}
.icon-bar {
     background-color: white;
}
.navbar-nav {
  margin: 0px;
}
.navbar-collapse {
    padding-right: 0px;
    padding-left: 0px;
    box-shadow: none;
    -webkit-box-shadow: none;
}
.navbar-brand {
    padding: 0;
    height: 50px;
}
.navbar-right li {
    width: auto;

}

@media (max-width: 1199px){
     .navbar ul {
        float: left;
     }
     .navbar-brand img {
       width: 191px;
     }
     .navbar-nav li {
        width: 134px;
        /*width: 157px;*/
     }
}

@media (max-width: 991px){
    .navbar ul {
       float: left;
    }
    .navbar li {
        width: 100px;
        /*width: 123px;*/
        font-size: 15px;
        margin: 0 1px;
    }
    .navbar {
        margin-bottom: 0px;
    }
}

@media (max-width: 767px){
     .navbar {
        margin-bottom: 20px;
     }
     .navbar ul {
        float: none;
        text-align: center;
        margin-top: 70px;
     }
    .navbar li {
        width: 100%;
        margin: 0 auto;
        background-color: none;
        text-align: center;
    }
    .navbar a {
        color: #03533a;
    }
    .nav > li > a:hover, .nav > li > a:focus {
        background-color: #03533a;
        color: #FFFFFF;
        cursor: pointer;
    }
    .nav-pills > li > a {
        border-radius: 0;
    }
    .navbar-brand {
        padding: 0;
    }
}

@media (max-width: 480px){
    .navbar {
        margin-bottom: 0;
    }
    .navbar-brand img {
        width: 181px;
    }
}

@media (min-width: 347px){
    .navbar-toggle {
         margin-top: 23px;
    }
}

@media (max-width: 346px){
    .navbar-brand {
        padding: 0px;
    }
}


/* NAV - TOGGLE BUTTON */
.animated-toggle {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 20px;
    right: 0;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    z-index: 1051;
}
.animated-toggle .icon-circle {
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    position: absolute;
    height: 44px;
    width: 44px;
    top: 2px;
    border-radius: 100%;
    background: transparent;
    left: -12px;
}
.animated-toggle .icon {
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    position: absolute;
    height: 3px;
    width: 21px;
    top: 23px;
    background-color: #ffffff;
    border-radius: 4px;
}
.animated-toggle .icon:before {
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    position: absolute;
    width: 21px;
    height: 3px;
    background-color: #ffffff;
    content: "";
    top: -8px;
    border-radius: 4px;
}
.animated-toggle .icon:after {
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    position: absolute;
    width: 21px;
    height: 3px;
    background-color: #ffffff;
    content: "";
    top: 8px;
    border-radius: 4px;
}
.animated-toggle.open .icon-circle {
    background: transparent;
}
.modal-open .animated-toggle .icon {
    background-color: #03533a;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    background: transparent;
}
.modal-open .animated-toggle .icon:before {
    background-color: #03533a;
    -webkit-transform: rotateZ(45deg) scaleX(1.25) translate(4.5px, 5.2px);
    transform: rotateZ(45deg) scaleX(1.25) translate(4.5px, 5.2px);
}
.modal-open .animated-toggle .icon:after {
    background-color: #03533a;
    -webkit-transform: rotateZ(-45deg) scaleX(1.25) translate(5px, -6px);
    transform: rotateZ(-45deg) scaleX(1.25) translate(5px, -6px);
}
.animated-toggle:hover {
    cursor: pointer;
}

/* NAV - MOBILE MENU */
.modal-open {
    overflow: hidden;
}
.modal {
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}
.modal.fade .modal-dialog {
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    -o-transform: translate(0, -25%);
    transform: translate(0, -25%);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
}
.modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}
.modal-open .modal {
    overflow: hidden;
}
.modal-dialog {
    position: relative;
    width: auto;
    top: 0;
}
.modal-content {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #999999;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    -webkit-background-clip: padding-box;
            background-clip: padding-box;
    outline: 0;
}
.modal-backdrop {
    position: fixed;
    top: 90px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: none;
}
.modal-backdrop.fade {
    opacity: 0;
    filter: alpha(opacity=0);
}
.modal-backdrop.in {
    opacity: 0.9;
    filter: alpha(opacity=90);
}
.modal-body {
    position: relative;
    padding: 15px;
}
#modal-nav li{
    color: #03533a;
    font-family: "alternate-gothic-no-3-d";
    font-size: 20px;
    font-weight: 400;
    outline: none;
    padding: 20px 0;
    text-align: center;
    text-transform: uppercase;
}
.modal-fullscreen {
    text-align: center;
    background: #FFFFFF;
}
.modal-fullscreen .modal-content {
    background: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.modal-backdrop.modal-backdrop-fullscreen {
    background: none;
}
.modal-backdrop.modal-backdrop-fullscreen.in {
    opacity: .97;
    filter: alpha(opacity=97);
}
.modal-fullscreen:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px; /* Adjust for spacing */
}
.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}
.modal-fullscreen .navbar-brand {
    position: absolute;
    top: 31px;
    left: 15px;
}
#modal-nav {
    margin-top: 30px;
}

@media (max-width: 480px){
  #modal-nav {
    margin-top: 40px;
  }
}

@media (max-width: 320px) {
  #modal-nav {
    margin-top: 50px;
  }
  #modal-nav li {
    padding: 15px 0;
  }
}





/* HOME */
.homeButton {
    padding-left: 0px;
    padding-right: 0px;
}
.homeButton2 {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 20px;
}

@media (min-width: 1999px){
  .homeButton {
      margin: 10px 0px;
  }
  .homeButton2 {
    margin: 10px 0px;
  }
}
@media (max-width: 1199px){
  .homeButton {
      margin: 10px 0px;
  }
  .homeButton2 {
    margin: 10px 0px;
  }
}

@media (max-width: 767px){
  .homeButton {
    position: relative;
    margin-bottom: 10px;
  }
  .homeButton2 {
    position: relative;
    margin-bottom: 10px;
  }
}
/* OVERLAY */
.wrapper {
    /*border: 4px solid #fff;*/
    position: relative;
    width: 96%;
    height: 100%;
}
.bottomImgs {
    margin: 10px 0px;
}
.bottomImgs img{
    padding: 0px;
    margin: 0px;
    border: none;
    width: 100%;
}
.bottomImgs2 {
    margin: 10px 0px;
}
.bottomImgs2 img{
    /*max-width: 231px;*/
    padding: 0px;
    margin: 0px;
    border: none;
}
.bannerText {
    position: absolute;
    width: 100%;
    text-align: center;
    background-color: rgba(3, 83, 58, .8);
    color: #FFF;
    z-index: 20;
    bottom: 0px;
    padding: 10px 50px;
}
.bannerText:hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: #03533a;
}
@media (max-width: 768px){
    .wrapper {
        width: 100%;
    }
}


/* SLIDER */
#slider img {
    max-width: 100%;
    border: 0;
}

/* HOME CONTENT */
#homeContent {
    margin: 80px 0px;
    font-weight: 300;
}
#homeContent .col-lg-7 {
    padding: 0px 0px;
    padding-right: 50px;
    border-right: 5px solid #296d57;
}
#homeContent .col-lg-5 {
    text-align: center;
    margin-left: 0px;
    margin-top: 35px;
    padding-left: 20px;
}
#homeContent img {
    max-width: 100%;
    margin-left: 30px;
    border: none;
}
#mission {
    margin-bottom: 60px;
}
#mission h2 {
    text-align: center;
}
#mission p {
    font-size: 22px;
    text-align: center;
    margin: 0 auto;
    max-width: 85%;
}
#mission h5 {
    text-align: center;
    margin: 20px 0 0 0;
    color: #81a99d;
}
#mission h5:hover {
    text-align: center;
    margin: 20px 0 0 0;
    color: #FFF;
}
#coreValues {
    margin-top: 60px;
}
#coreValues h2 {
    text-align: center;
    margin-bottom: 15px;
}
#coreValues p {
    text-align: center;
    margin-bottom: 8px;
}

@media (max-width: 1199px){
      #homeContent .col-lg-7 {
        border-right: none;
    }
      #homeContent {
        margin-bottom: 30px;
      }
}
@media (max-width: 991px){
    #homeContent {
        margin: 50px 0px;
    }
    #homeContent .col-lg-5 {
        margin-top: 0px;
    }
    #homeContent .col-lg-7 {
        padding: 0px 10px;
    }
}


/* SENIOR LIVING */
/*****************/
.intro {
    margin-top: 70px;
}
.intro h2 {
    display: none;
}
.intro p {
    font-size: 22px;
    font-weight: 300;
}
.feature {
    margin-top: 60px;
}
.feature img {
    max-width: 100%;
}
.portfolio {
    margin-top: 60px;
}
.portfolioPics {
    margin-bottom: 70px;
}
.portfolioPics .col-lg-3 img{
    height: 250px;
    width: 250px;
    margin-top: 20px;
}
.portfolio p {
    margin-bottom: 20px;
}
.hoverWrapper {
    opacity: 0;
    top: 20px;
    position: absolute;
    height: 250px;
    width: 242px;
}
.hoverWrapper:hover {
    opacity: 1;
}
.hover {
    padding: 8px 0px;
    position: absolute;
    text-align: center;
    bottom: 30px;
    left: 4px;
    width: 100%;
    background-color: rgba(3, 83, 58, .8);
    color: white;
}

@media(max-width: 991px) {
    .feature .col-md-7 {
        margin-top: 30px;
    }
    .hoverWrapper {
        top: inherit;
    }
    .hover, .hoverWrapper {
        margin: 20px;
        z-index: 20;
        left: 50%;
        margin-left: -121px;
    }
    .portfolioPics .col-lg-3 img {
        position: relative;
        left: 50%;
        margin-left: -125px;
    }
}

@media(min-width: 992px) {
  .feature .col-md-7 {
      padding-left: 15px;
  }
}

/** GALLERY **/
/********************/
.galleryImg {
    margin: 30px 0px;
}
/*.galleryImg:nth-child(1) {
  margin-top: 80px;
}*/
.galleryImg img {
    max-width: 100%;
}
#project-info {
    max-width: 75%;
    margin: 0 auto 80px auto;
    text-align: center;
}
#project-info h2 {
    font-size: 35px;
}
#project-info p {
    font-size: 20px;
    font-weight: 300;
}
#project-info .button {
    margin: 30px auto;
}
#project-gallery {
    max-width: 60%;
    margin: 0 auto;
    text-align: center;
}
#project-gallery img {
    border: 4px solid #FFF;
}
@media (max-width: 991px){
    #project-info {
        max-width: 100%;
        margin: 0 auto 60px auto;
        text-align: left;
    }
    #project-info .button {
        margin: 30px 0;
    }
    #project-gallery {
        max-width: 100%;
        margin: 0;
        text-align: left;
    }
}
/*.galleryImg p {
    margin-bottom: 30px;
    padding-right: 15px;
}
.left-img {
    max-width: 85%;
    margin-right: 30px;
    float: left;
}
.right-img {
    max-width: 85%;
}

@media(max-width: 991px) {
    .left-img {
        margin-bottom: 30px;
    }
}
@media(min-width: 1199px){
    .left-img {
        margin-right: 0px;
    }
    .left-img img {
        width: 350px;
    }
    .right-img img {
        width: 350px;
        float: right;
    }

}*/



/* OUR TEAM */
/**************/
#banner {
    position: relative;
}
#banner img {
    max-width: 100%;
}
.titleBanner {
    text-align: center;
    position: absolute;
    bottom: 4px;
    left: 4px;
    width: 35%;
    background-color: rgba(3, 83, 58, .8);
}
.team {
    margin-top: 50px;
}
.team .featureImg {
    width: 250px;
}
.team:first-child {
    margin-top: 80px;
}
.teamImg img{
    position: relative;
    max-width: 100%;
}
.teamImg {
    padding: 0 15px;
}
.team h3 {
    margin-bottom: 10px;
}
.teamTitle {
    opacity: 0.6;
}
@media(min-width: 991px) and (max-width: 1199px){
    .team h2, .team p {
        padding-left: 15px;
    }
    .titleBanner {
        width: 40%;
    }
}
@media(max-width: 991px){
    .teamImg {
        padding: 0;
    }
    .titleBanner {
        width: 40%;
    }
    .team h2 {
        margin: 10px 0px;
    }
}
@media(max-width: 767px){
    .titleBanner h1 {
        font-size: 24px;
    }
    .titleBanner {
        width: 50%;
    }
}
@media(max-width: 500px){
    .titleBanner {
        width: 70%;
    }
}




/* CONTACT */
/***********/
.intro {
    margin-bottom: 80px;
}
.contact-us h5 {
    margin-top: 20px;
}
.contact-us img {
    margin: 25px 0;
    max-width: 100%;
}
/*Form*/
form {
    border: 4px solid #296d57;
    padding: 35px;
    margin-top: 30px;
    color: #FFF;
}
form input {
    background: #296d57;
	width: 60%;
	padding: 14px;
	margin-bottom: 25px;
    box-shadow: none;
	border: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
#fcomments {
    background-color: #296d57;
    width: 85%;
	height: 110px;
	padding:8px;
    margin-bottom: 20px;
    line-height: 1.8em;
	resize: none;
    box-shadow: none;
	border: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
form button {
    background-color: #296d57;
	width: 130px;
	height: 50px;
	box-shadow: none;
	border: 0;
	cursor: pointer;
	text-align: center;
}
form button:hover {
	background-color: #81a99d;
}
input:focus, select:focus, textarea:focus {
    outline-size: 1px;
    outline-color: #81a99d;
    border-radius: 0 !important;
}
input, label, button {
    display: block;
}

@media(max-width: 991px){
    #form {
       margin-top: 60px;
    }
}
#result {
    margin-bottom: 20px;
}


/* FOOTER */
/**********/
#footer {
    padding: 0px;
    font-size: 14px;
    margin-top: 80px;
    border-top: 5px solid #296d57;
}
#footer h6 {
    margin-top: 15px;
}

@media(max-width:991px){
    #footer {
        margin-top: 50px;
    }
}
@media(max-width:767px){
    #footer {
        margin-top: 50px;
    }
}




/* SOCIAL */
/*********/
#icons {
    font-size: 18px;
    text-align: right;
}
#icons a {
    text-align: center;
    text-decoration: none;
    color: #FFF;
}
#icons i {
    line-height: 30px;
}
.social {
    width: 30px;
    height: 30px;
    margin: 16px 0px;
    background-color: #296d57;
}
.social:hover {
    background-color: #81a99d;
}

@media (max-width: 768px){
    #footer, #icons {
        text-align: center;
    }
}
.navbar-header {
    width: 100%;
}
.navbar-social {
    float: right !important;
}
.navbar-social .social-icon {
    background-color: #296d57;
    font-size: 18px;
    line-height: 30px;
    margin-left: 2px;
    width: 30px;
    height: 30px;
    text-align: center;
}
.navbar-social ul {
    margin-top: 0px;
}
.navbar-nav.navbar-social {
    margin-top: 24px;
}
.social-icon:hover {
    background-color: #81a99d;
}

.navbar-nav li a:hover > .social {
    background-color: #81a99d;
}

@media (max-width: 991px) {
    .navbar-social {
      display: none;
    }
}
